import Vue from "vue";
import VueRouter from "vue-router";
import jwt_decode from "jwt-decode";

Vue.use(VueRouter);

// owner manager accountant staff

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL, //process.env.BASE_URL ///path ของ url
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: () => import("@/views/Home.vue"),
      meta: {
        pageTitle: "Home",
        middleware: ["owner", "manager", "accountant", "staff"],
        breadcrumb: [
          {
            text: "Home",
            active: true,
          },
        ],
      },
    },
    {
      path: "/second-page",
      name: "second-page",
      component: () => import("@/views/SecondPage.vue"),
      meta: {
        pageTitle: "Second Page",
        middleware: ["owner", "manager", "accountant", "staff"],
        breadcrumb: [
          {
            text: "Second Page",
            active: true,
          },
        ],
      },
    },
    {
      path: "/accessories/view/:id",
      name: "accessories-view",
      component: () => import("@/views/admin/accessories/accessories-view.vue"),
      meta: {
        pageTitle: "อุปกรณ์",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/accessories/list",
      name: "accessories-list",
      component: () => import("@/views/admin/accessories/accessories-list.vue"),
      meta: {
        pageTitle: "อุปกรณ์ที่ให้ไปด้วย",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/product-kit/list",
      name: "product-kit-list",
      component: () => import("@/views/admin/product-kit/product-kit-list.vue"),
      meta: {
        pageTitle: "อุปกรณ์จัดชุดทั้งหมด",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/product-kit/view/:id",
      name: "product-kit-view",
      component: () => import("@/views/admin/product-kit/product-kit-view.vue"),
      meta: {
        pageTitle: "รายละเอียดจัดชุด",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/product/list",
      name: "product-list",
      component: () => import("@/views/admin/product/product-list.vue"),
      meta: {
        pageTitle: "อุปกรณ์ทั้งหมด",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/product/view/:id",
      name: "product-view",
      component: () => import("@/views/admin/product/product-view.vue"),
      meta: {
        pageTitle: "รายละเอียดอุปกรณ์",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/today/", /// action = get,return /// method = self,messenger,kerry :action/:method
      name: "order-today",
      component: () => import("@/views/admin/order/order-today.vue"),
      meta: {
        pageTitle: "Order TEST",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/pickup/",
      name: "order-pickup",
      component: () => import("@/views/admin/order/order-pickup.vue"),
      meta: {
        pageTitle: "Order รับวันนี้",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/return/",
      name: "order-return",
      component: () => import("@/views/admin/order/order-return.vue"),
      meta: {
        pageTitle: "Order คืนวันนี้",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/list",
      name: "order-list",
      component: () => import("@/views/admin/order/order-list.vue"),
      meta: {
        middleware: ["owner", "manager", "accountant", "staff"],
        pageTitle: "Order ทั้งหมด",
      },
    },
    {
      path: "/order/create",
      name: "order-create",
      component: () => import("@/views/admin/order/order-create.vue"),
      meta: {
        pageTitle: "สร้าง Order ใหม่",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/view/:id",
      name: "order-view",
      component: () => import("@/views/admin/order/order-view.vue"),
      meta: {
        // pageTitle: "View Order",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/print/:id",
      name: "order-print",
      // component: () => import("@/views/admin/order/order-print-xxx.vue"),
      component: () => import("@/views/admin/order/order-print.vue"),
      meta: {
        layout: "full",
        pageTitle: "Print Order",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    // {
    //   path: "/order/print-test",
    //   name: "order-test-print",
    //   component: () => import("@/views/admin/order/order-print.vue"),
    //   meta: {
    //     layout: "full",
    //     pageTitle: "Print Order",
    //     middleware: ["owner", "manager", "accountant", "staff"],
    //   },
    // },
    ////// ORDER STATUS START /////
    {
      path: "/order/status/pending-deposit",
      name: "pending-deposit",
      component: () => import("@/views/admin/order/status/pending-deposit.vue"),
      meta: {
        pageTitle: "รอยอดวางประกัน",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/status/pending-call",
      name: "pending-call",
      component: () => import("@/views/admin/order/status/pending-call.vue"),
      meta: {
        pageTitle: "รอโทรแจ้งยอดวางประกัน",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/status/not-response",
      name: "not-response",
      component: () => import("@/views/admin/order/status/not-response.vue"),
      meta: {
        pageTitle: "ติดต่อไม่ได้",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/status/transfered",
      name: "transfered",
      component: () => import("@/views/admin/order/status/transfered.vue"),
      meta: {
        pageTitle: "แจ้งโอนเงิน",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/account/pending-refund",
      name: "pending-refund",
      component: () => import("@/views/admin/account/pending-refund.vue"),
      meta: {
        pageTitle: "รอโอนประกันคืน",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/status/pending-transfer",
      name: "pending-transfer",
      component: () =>
        import("@/views/admin/order/status/pending-transfer.vue"),
      meta: {
        pageTitle: "รอโอนค่าเช่า",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/status/pending-info",
      name: "pending-info",
      component: () => import("@/views/admin/order/status/pending-info.vue"),
      meta: {
        pageTitle: "รอลูกค้าทำรายการ",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/order/status/pending-confirm",
      name: "pending-confirm",
      component: () => import("@/views/admin/order/status/pending-confirm.vue"),
      meta: {
        pageTitle: "รอลูกค้าตอบกลับ",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },

    {
      path: "/order/pre-book",
      name: "pre-book",
      component: () => import("@/views/admin/order/order-pre-book.vue"),
      meta: {
        pageTitle: "ใบจอง",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },

    ///// ORDER STATUS END //////
    {
      path: "/queue/list",
      name: "queue-list",
      component: () => import("@/views/admin/queue/queue-list.vue"),
      meta: {
        // middleware:['admin'],
        pageTitle: "คิวจองทั้งหมด",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/member/list",
      name: "member-list",
      component: () => import("@/views/admin/member/member-list.vue"),
      meta: {
        pageTitle: "สมาชิกทั้งหมด",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/member/blacklist-list",
      name: "blacklist-list",
      component: () => import("@/views/admin/member/blacklist-list.vue"),
      meta: {
        pageTitle: "Black List",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/member/view/:id",
      name: "member-view",
      component: () => import("@/views/admin/member/member-view.vue"),
      meta: {
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },

    {
      path: "/tax-invoice/list",
      name: "tax-invoice-list",
      component: () => import("@/views/admin/tax-invoice/tax-invoice-list.vue"),
      meta: {
        pageTitle: "ใบกำกับภาษี",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/tax-invoice/create/:id",
      name: "tax-invoice-create",
      component: () =>
        import("@/views/admin/tax-invoice/tax-invoice-create.vue"),
      meta: {
        pageTitle: "ใบกำกับภาษี",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/tax-invoice/print/:id",
      name: "tax-invoice-print",
      component: () =>
        import("@/views/admin/tax-invoice/tax-invoice-print.vue"),
      meta: {
        layout: "full",
        pageTitle: "ใบกำกับภาษี",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/tax-invoice/edit/:id",
      name: "tax-invoice-edit",
      component: () => import("@/views/admin/tax-invoice/tax-invoice-edit.vue"),
      meta: {
        pageTitle: "แก้ไขใบกำกับภาษี",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/tax-invoice/month-summary",
      name: "tax-invoice-month-summary",
      component: () =>
        import("@/views/admin/tax-invoice/tax-invoice-month-summary.vue"),
      meta: {
        pageTitle: "สรุปเดือนใบกำกับภาษี",
        middleware: ["owner", "manager", "accountant"],
      },
    },

    {
      path: "/login",
      name: "login",
      component: () => import("@/views/Login.vue"),
      meta: {
        layout: "full",
      },
    },

    ////// Start Account SECTION ////////

    {
      path: "/account/today",
      // redirect: '/user/dashboard',
      name: "account-today",
      component: () => import("@/views/admin/account/today.vue"),
      meta: {
        completeinfo: true,
        middleware: ["owner", "manager", "accountant"],
      },
    },
    {
      path: "/account/each",
      // redirect: '/user/dashboard',
      name: "account-each",
      component: () => import("@/views/admin/account/each.vue"),
      meta: {
        completeinfo: true,
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/account/deposit",
      // redirect: '/user/dashboard',
      name: "deposit-list",
      component: () => import("@/views/admin/account/deposit.vue"),
      meta: {
        completeinfo: true,
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/account/day-close",
      name: "day-close",
      component: () => import("@/views/admin/account/day-close.vue"),
      meta: {
        layout: "full",
        // completeinfo: true,
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/account/drive",
      // redirect: '/user/dashboard',
      name: "account-drive",
      component: () => import("@/views/admin/account/drive.vue"),
      meta: {
        completeinfo: true,
        middleware: ["owner", "manager", "accountant"],
      },
    },
    {
      path: "/book-bank",
      name: "book-bank-list",
      component: () => import("@/views/admin/book-bank/index.vue"),
      meta: {
        // middleware: ["admin"],
        completeinfo: true,
        middleware: ["owner", "manager", "accountant"],
      },
    },
    {
      path: "/deposit-predict-log",
      name: "deposit-predict-log",
      component: () => import("@/views/admin/log/deposit-predict.vue"),
      meta: {
        // middleware: ["admin"],
        completeinfo: true,
        middleware: ["owner"],
      },
    },
    {
      path: "/check-log",
      name: "check-log",
      component: () => import("@/views/admin/log/check-log.vue"),
      meta: {
        // middleware: ["admin"],
        completeinfo: true,
        middleware: ["owner"],
      },
    },
    {
      path: "*",
      redirect: "error-404",
    },
    {
      path: "/error-404",
      name: "error-404",
      component: () => import("@/views/error/Error404.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/no-permission",
      name: "no-permission",
      component: () => import("@/views/error/NoPermission.vue"),
      meta: {
        layout: "full",
      },
    },
    {
      path: "/stock/image",
      name: "stock-image",
      component: () => import("@/views/admin/product/stock-image.vue"),
      meta: {
        layout: "full",
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/tasks/one",
      name: "task-one",
      component: () => import("@/views/admin/product/product-update-bulk.vue"),
      meta: {
        middleware: ["owner", "manager", "accountant", "staff"],
      },
    },
    {
      path: "/google-contact/init",
      name: "goole-contact-init",
      component: () => import("@/views/admin/setup/google-contact.vue"),
      meta: {
        middleware: ["owner"],
      },
    },

    ////// End Account SECTION ////////
  ],
});

router.beforeEach(async (to, from, next) => {
  // console.log("before each", process.env.BASE_URL);

  if ("middleware" in to.meta) {
    console.log("this page request middleware");
    const accessToken = localStorage.getItem("accessToken");
    console.log("accessToken", accessToken);
    if (accessToken) {
      console.log("คุณมี Token", localStorage.getItem("accessToken"));
      const permit_role = to.meta.middleware; /// role ที่เข้าได้
      console.log("permit_role", permit_role);
      const time_now_unix = Date.now(); /// 1000;

      try {
        const jwt_decoded = jwt_decode(accessToken);
        console.log("your role", jwt_decoded.role);
        if (new Date() > new Date(jwt_decoded.exp * 1000)) {
          router.push("login");
        }
        if (permit_role.includes(jwt_decoded.role)) {
          next();
        } else {
          /// ไม่มี Role
          console.log("ไม่มี Role");
          router.push("no-permission");
        }
      } catch (error) {
        // console.log("JWT Error");
        router.push("no-permission");
      }
    } else {
      console.log("คุณไม่มี Token");
      router.push("login");
    }
  }
  next();
});

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  console.log("after each");
  // Remove initial loading
  const appLoading = document.getElementById("loading-bg");
  if (appLoading) {
    appLoading.style.display = "none";
  }
});

export default router;
