import { $themeConfig } from "@themeConfig";
import _ from "lodash";

export default {
  namespaced: true,
  state: {
    carts: [],
    info: {},
  },
  getters: {
    getCarts: (state) => {
      return state.carts;
    },
    getInfo: (state) => {
      return state.info;
    },
  },
  mutations: {
    addToCart(state, val) {
      val.cart_id = Math.random().toString(36).slice(2);
      state.carts.push(val);
    },
    deleteCart(state, val) {
      const index = _.findIndex(state.carts, { cart_id: val });
      state.carts.splice(index, 1);
    },
    updateCheckPrice(state, val) {
      state.carts = val.carts;
    },
    updateInfo(state, val) {
      state.info = val;
    },
  },
  actions: {
    addToCart: (context, payload) => {
      context.commit("addToCart", payload);
    },
    deleteCart: (context, payload) => {
      context.commit("deleteCart", payload);
    },
    updateCheckPrice: (context, payload) => {
      context.commit("updateCheckPrice", payload);
    },
    updateInfo: (context, payload) => {
      context.commit("updateInfo", payload);
    },
  },
};
