import Vue from "vue";
import { ToastPlugin, ModalPlugin } from "bootstrap-vue";
import VueCompositionAPI from "@vue/composition-api";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import router from "./router";
import store from "./store";
import App from "./App.vue";
import Axios from "axios";

import VueCookies from "vue-cookies";

console.log("axios_mode", process.env.NODE_ENV);

if (process.env.NODE_ENV === "development") {
  console.log("process.env.NODE_ENV", process.env.NODE_ENV);
  Axios.defaults.baseURL = "http://localhost:3080/admin/";
} else {
  Axios.defaults.baseURL = "https://api.lenslineup.com/admin/";
}

//// FIREBASE START 2

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD4J73CalWywyFugruSpchONH2168UeREY",
  authDomain: "lenslineup22andbeyond.firebaseapp.com",
  projectId: "lenslineup22andbeyond",
  storageBucket: "lenslineup22andbeyond.appspot.com",
  messagingSenderId: "59760165962",
  appId: "1:59760165962:web:89c0e2c50c6277ae5552b3",
  measurementId: "G-MB190LTL3R",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

import "quill/core";

/// FIREBASE END

// Global Components
import "./global-components";

// 3rd party plugins
import "@/libs/portal-vue";
import "@/libs/toastification";

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);

// Composition API
Vue.use(VueCompositionAPI);

// cookie
Vue.use(VueCookies);

// import core styles
require("@core/scss/core.scss");

// import assets styles
require("@/assets/scss/style.scss");

Vue.config.productionTip = false;

Vue.prototype.$http = Axios;

Vue.mixin({
  methods: {
    toast_success(title, text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "CheckIcon",
          text: text,
          variant: "success",
        },
      });
    },
    toast_error(title, text = "") {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "XIcon",
          text: text,
          variant: "danger",
        },
      });
    },
  },
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
